export const incidentIcons = {
// Reference from Rhodium3 module/Application/view/partials/incident_icons.phtml
  general: [
    'default.png',
    'icon3.png',
    'G1.png',
    'G2.png',
    'G5.png',
    'G9.png',
    'G11.png',
    'G13.png',
    'G15.png',
    'exit.png',
    'binoculars.png',
    'door_breach_1.png',
    'door_breach_2.png',
    'earthquake.png',
    'flooding_1.png',
    'flooding_2.png',
    'winter_storm.png',
    'fallen_tree.png',
    'water_2.png',
    'icon1.png',
    'icon6.png',
    'icon7.png',
    'icon8.png',
    'icon10.png',
    'icon11.png',
    'icon14.png',
    'LM1.png',
    'LM2.png',
    'LM3.png',
    'LM4.png',
    'LM6.png',
    'LM7.png',
    'LM11.png',
    'LM13.png',
    'LM20.png',
    'LM21.png',
    'LM70.png',
    'LM48.png',
    'LM23.png',
    'LM24.png',
    'LM26.png',
    'LM27.png',
    'LM29.png',
    'LM31.png',
    'LM32.png',
    'LM33.png',
    'LM34.png',
    'LM35.png',
    'LM36.png',
    'LM37.png',
    'LM38.png',
    'LM39.png',
    'LM45.png',
    'beer.png',
    'do_not_enter.png',
    'explosive_1.png',
    'explosive_2.png',
    'gas_green.png',
    'gas_red.png',
    'gas_white.png',
    'gas_yellow.png',
    'gen_0.png',
    'gen_1.png',
    'generator.png',
    'generator_2.png',
    'golf_cart.png',
    'green_checkbox.png',
    'ied_1.png',
    'ied_2.png',
    'misc_gis.png',
    'money.png',
    'no_comms.png',
    'no_go.png',
    'no_power.png',
    'no_water.png',
    'parking.png',
    'special_event.png',
    'special_event_2.png',
    'tower.png',
    'traffic_0.png',
    'traffic_1.png',
    'LM46.png',
    'LM50.png',
    'LM55.png',
    'LM51.png',
    'LM56.png',
    'LM59.png',
    'LM60.png',
    'LM61.png',
    'LM62.png',
    'LM47.png',
    'LM52.png',
    'LM57.png',
    'LM63.png',
    'LM64.png',
    'LM65.png',
    'LM66.png',
    'LM85.png',
    'emergency_siren_0.png',
    'emergency_siren_1.png',
    'windsock.png',
    'direction_north.png',
    'direction_northwest.png',
    'direction_west.png',
    'direction_southwest.png',
    'direction_south.png',
    'direction_southeast.png',
    'direction_east.png',
    'direction_northeast.png',
    'EOC.png',
    'cs_yellow.png',
    'cs_red.png',
    'drowning.png',
    'rising_waters.png',
    'sinking.png',
  ],
  incident_location: [
    'church.png',
    'mosque.png',
    'synagog.png',
    'damaged_structure.png',
    'doctors_office.png',
    'emergency_shelter.png',
    'hospital.png',
    'icon13.png',
    'LM44.png',
  ],
  law_enforcement: [
    'badge_blue.png',
    'badge_black.png',
    'badge_red.png',
    'L1.png',
    'L2.png',
    'L3.png',
    'G3.png',
    'G12.png',
    'LASD.png',
    'LASD_Assign1.png',
    'LASD_Assign2.png',
    'LASD_Assign3.png',
    'LASD_AssignA.png',
    'LASD_AssignB.png',
    'LASD_AssignC.png',
    'LASD_AssignD.png',
    'LM25.png',
    'LM71.png',
    'LM73.png',
    'LM74.png',
    'LM75.png',
    'LM76.png',
    'LM78.png',
    'LM79.png',
    'LM80.png',
    'LM58.png',
    'LM49.png',
    'LM53.png',
  ],
  fire_ems: [
    'F1.png',
    'F2.png',
    'F3.png',
    'F4.png',
    'F5.png',
    'F6.png',
    'G10.png',
    'G14.png',
    'hazmat.png',
    'er.png',
    'er_2.png',
    'LM8.png',
    'LM14.png',
    'icon12.png',
    'LM15.png',
    'LM16.png',
    'LM17.png',
    'LM18.png',
    'LM19.png',
    'LM9.png',
    'LM30.png',
    'LM83.png',
    'LM84.png',
    'LM67.png',
    'LM68.png',
    'LM69.png',
    'LM72.png',
    'LM82.png',
    'LM81.png',
    'icon5.png',
    'decon.png',
    'fire_attack_1.png',
    'fire_attack_2.png',
    'incident_command.png',
    'investigation.png',
    'primary_search.png',
    'rapid_intervention_crew.png',
    'road_block.png',
    'search_and_rescue_1.png',
    'search_and_rescue_2.png',
    'secondary_search.png',
    'ventilation_1.png',
    'ventilation_2.png',
    'PIV-inc.png',
    'SPIV-inc.png',
    'EOL-PIV-inc.png',
  ],
  utilities_oil: [
    'crewtruck.png',
    'servicetruck.png',
    'pressuretruck.png',
    'weldrig.png',
    'excavator.png',
    'vaporextractor.png',
    'directionaldrill.png',
    'supervisor.png',
    'manager.png',
    'operationschief.png',
    'fsrsingle.png',
    'fsrcrew.png',
    'mechanicsingle.png',
    'mechaniccrew.png',
    'leadersingle.png',
    'leadercrew.png',
    'weldersingle.png',
    'weldercrew.png',
    'constructionsingle.png',
    'constructioncrew.png',
    'surveycrewsingle.png',
    'surveycrewcrew.png',
    'testingcrewsingle.png',
    'testingcrewcrew.png',
    'publicinfo.png',
    'claimsregulatory.png',
    'leakcheck.png',
    'leakchecknotpresent.png',
    'leakcheckpresent.png',
    'evacuated.png',
    'venting.png',
    'odorcheck.png',
    'valveopen.png',
    'valveclosed.png',
    'controlpressure.png',
    'stopper.png',
    'squeezepoint.png',
    'bypass.png',
    'laneclosed.png',
    'roadclosed.png',
    'excavation.png',
  ],
  colors: [
    'color_1.png',
    'color_2.png',
    'color_3.png',
    'color_4.png',
    'color_5.png',
    'color_6.png',
    'color_7.png',
    'color_8.png',
    'color_9.png',
    'color_10.png',
    'color_11.png',
    'color_12.png',
    'color_13.png',
    'color_15.png',
    'color_16.png',
    'color_17.png',
    'color_18.png',
    'LM5.png',
    'LM10.png',
    'LM28.png',
  ],
  letters_numbers: [
    '01_light.png',
    '02_light.png',
    '03_light.png',
    '04_light.png',
    '05_light.png',
    '06_light.png',
    '07_light.png',
    '08_light.png',
    '09_light.png',
    '10_light.png',
    '01.png',
    '02.png',
    '03.png',
    '04.png',
    '05.png',
    '06.png',
    '07.png',
    '08.png',
    '09.png',
    '10.png',
    '11.png',
    '12.png',
    '13.png',
    '14.png',
    '15.png',
    '16.png',
    '17.png',
    '18.png',
    '19.png',
    '20.png',
    '21.png',
    '22.png',
    '23.png',
    '24.png',
    '25.png',
    '26.png',
    '27.png',
    '28.png',
    '29.png',
    '30.png',
    'a.png',
    'b.png',
    'c.png',
    'd.png',
    'e.png',
    'f.png',
    'g.png',
    'h.png',
    'i.png',
    'j.png',
    'k.png',
    'l.png',
    'm.png',
    'n.png',
    'o.png',
    'p.png',
    'q.png',
    'r.png',
    's.png',
    't.png',
    'u.png',
    'v.png',
    'w.png',
    'x.png',
    'y.png',
    'z.png',
    'za.png',
    'zb.png',
    'zc.png',
    'zd.png',
    'ze.png',
    'zf.png',
    'zg.png',
    'zh.png',
    'zi.png',
  ],
  rhodium: [
    'CommandScope.png',
    'Gate.png',
    'RhodiumPreplan.png',
    'checkpoint.png',
  ],
};

export const landmarksIcons = {
  general: [
    'default.png',
    'icon3.png',
    'G1.png',
    'G2.png',
    'G5.png',
    'G9.png',
    'G11.png',
    'G13.png',
    'G15.png',
    'exit.png',
    'binoculars.png',
    'door_breach_1.png',
    'door_breach_2.png',
    'earthquake.png',
    'flooding_1.png',
    'flooding_2.png',
    'winter_storm.png',
    'fallen_tree.png',
    'water_2.png',
    'icon1.png',
    'icon6.png',
    'icon7.png',
    'icon8.png',
    'icon10.png',
    'icon11.png',
    'icon14.png',
    'LM1.png',
    'LM2.png',
    'LM3.png',
    'LM4.png',
    'LM6.png',
    'LM7.png',
    'LM11.png',
    'LM13.png',
    'LM20.png',
    'LM21.png',
    'LM70.png',
    'LM48.png',
    'LM23.png',
    'LM24.png',
    'LM26.png',
    'LM27.png',
    'LM29.png',
    'LM31.png',
    'LM32.png',
    'LM33.png',
    'LM34.png',
    'LM35.png',
    'LM36.png',
    'LM37.png',
    'LM38.png',
    'LM39.png',
    'LM45.png',
    'beer.png',
    'do_not_enter.png',
    'explosive_1.png',
    'explosive_2.png',
    'gas_green.png',
    'gas_red.png',
    'gas_white.png',
    'gas_yellow.png',
    'gen_0.png',
    'gen_1.png',
    'generator.png',
    'generator_2.png',
    'golf_cart.png',
    'green_checkbox.png',
    'ied_1.png',
    'ied_2.png',
    'misc_gis.png',
    'money.png',
    'no_comms.png',
    'no_go.png',
    'no_power.png',
    'no_water.png',
    'parking.png',
    'special_event.png',
    'special_event_2.png',
    'tower.png',
    'traffic_0.png',
    'traffic_1.png',
    'LM46.png',
    'LM50.png',
    'LM55.png',
    'LM51.png',
    'LM56.png',
    'LM59.png',
    'LM60.png',
    'LM61.png',
    'LM62.png',
    'LM47.png',
    'LM52.png',
    'LM57.png',
    'LM63.png',
    'LM64.png',
    'LM65.png',
    'LM66.png',
    'LM85.png',
    'emergency_siren_0.png',
    'emergency_siren_1.png',
    'windsock.png',
    'direction_north.png',
    'direction_northwest.png',
    'direction_west.png',
    'direction_southwest.png',
    'direction_south.png',
    'direction_southeast.png',
    'direction_east.png',
    'direction_northeast.png',
    'EOC.png',
    'cs_yellow.png',
    'cs_red.png',
    'drowning.png',
    'rising_waters.png',
    'sinking.png',
  ],
  incident_location: [
    'church.png',
    'mosque.png',
    'synagog.png',
    'damaged_structure.png',
    'doctors_office.png',
    'emergency_shelter.png',
    'hospital.png',
    'icon13.png',
    'LM44.png',
  ],
  law_enforcement: [
    'badge_blue.png',
    'badge_black.png',
    'badge_red.png',
    'L1.png',
    'L2.png',
    'L3.png',
    'G3.png',
    'G12.png',
    'LASD.png',
    'LASD_Assign1.png',
    'LASD_Assign2.png',
    'LASD_Assign3.png',
    'LASD_AssignA.png',
    'LASD_AssignB.png',
    'LASD_AssignC.png',
    'LASD_AssignD.png',
    'LM25.png',
    'LM71.png',
    'LM73.png',
    'LM74.png',
    'LM75.png',
    'LM76.png',
    'LM78.png',
    'LM79.png',
    'LM80.png',
    'LM58.png',
    'LM49.png',
    'LM53.png',
  ],
  fire_ems: [
    'F1.png',
    'F2.png',
    'F3.png',
    'F4.png',
    'F5.png',
    'F6.png',
    'G10.png',
    'G14.png',
    'hazmat.png',
    'er.png',
    'er_2.png',
    'LM8.png',
    'LM14.png',
    'icon12.png',
    'LM15.png',
    'LM16.png',
    'LM17.png',
    'LM18.png',
    'LM19.png',
    'LM9.png',
    'LM30.png',
    'LM83.png',
    'LM84.png',
    'LM67.png',
    'LM68.png',
    'LM69.png',
    'LM72.png',
    'LM82.png',
    'LM81.png',
    'icon5.png',
    'decon.png',
    'fire_attack_1.png',
    'fire_attack_2.png',
    'incident_command.png',
    'investigation.png',
    'primary_search.png',
    'rapid_intervention_crew.png',
    'road_block.png',
    'search_and_rescue_1.png',
    'search_and_rescue_2.png',
    'secondary_search.png',
    'ventilation_1.png',
    'ventilation_2.png',
    'PIV-lm.png',
    'SPIV-lm.png',
    'EOL-PIV-lm.png',
  ],
  utilities_oil: [
    'crewtruck.png',
    'servicetruck.png',
    'pressuretruck.png',
    'weldrig.png',
    'excavator.png',
    'vaporextractor.png',
    'directionaldrill.png',
    'supervisor.png',
    'manager.png',
    'operationschief.png',
    'fsrsingle.png',
    'fsrcrew.png',
    'mechanicsingle.png',
    'mechaniccrew.png',
    'leadersingle.png',
    'leadercrew.png',
    'weldersingle.png',
    'weldercrew.png',
    'constructionsingle.png',
    'constructioncrew.png',
    'surveycrewsingle.png',
    'surveycrewcrew.png',
    'testingcrewsingle.png',
    'testingcrewcrew.png',
    'publicinfo.png',
    'claimsregulatory.png',
    'leakcheck.png',
    'leakchecknotpresent.png',
    'leakcheckpresent.png',
    'evacuated.png',
    'venting.png',
    'odorcheck.png',
    'valveopen.png',
    'valveclosed.png',
    'controlpressure.png',
    'stopper.png',
    'squeezepoint.png',
    'bypass.png',
    'laneclosed.png',
    'roadclosed.png',
    'excavation.png',
  ],
  colors: [
    'color_1.png',
    'color_2.png',
    'color_3.png',
    'color_4.png',
    'color_5.png',
    'color_6.png',
    'color_7.png',
    'color_8.png',
    'color_9.png',
    'color_10.png',
    'color_11.png',
    'color_12.png',
    'color_13.png',
    'color_15.png',
    'color_16.png',
    'color_17.png',
    'color_18.png',
    'LM5.png',
    'LM10.png',
    'LM28.png',
  ],
  letters_numbers: [
    '01_light.png',
    '02_light.png',
    '03_light.png',
    '04_light.png',
    '05_light.png',
    '06_light.png',
    '07_light.png',
    '08_light.png',
    '09_light.png',
    '10_light.png',
    '01.png',
    '02.png',
    '03.png',
    '04.png',
    '05.png',
    '06.png',
    '07.png',
    '08.png',
    '09.png',
    '10.png',
    '11.png',
    '12.png',
    '13.png',
    '14.png',
    '15.png',
    '16.png',
    '17.png',
    '18.png',
    '19.png',
    '20.png',
    '21.png',
    '22.png',
    '23.png',
    '24.png',
    '25.png',
    '26.png',
    '27.png',
    '28.png',
    '29.png',
    '30.png',
    'a.png',
    'b.png',
    'c.png',
    'd.png',
    'e.png',
    'f.png',
    'g.png',
    'h.png',
    'i.png',
    'j.png',
    'k.png',
    'l.png',
    'm.png',
    'n.png',
    'o.png',
    'p.png',
    'q.png',
    'r.png',
    's.png',
    't.png',
    'u.png',
    'v.png',
    'w.png',
    'x.png',
    'y.png',
    'z.png',
    'za.png',
    'zb.png',
    'zc.png',
    'zd.png',
    'ze.png',
    'zf.png',
    'zg.png',
    'zh.png',
    'zi.png',
  ],
  rhodium: [
    'CommandScope.png',
    'Gate.png',
    'RhodiumPreplan.png',
    'Checkpoint.png',
  ],
};

export const maptoolsIcons = {
  general: [
    'default.png',
    'icon3.png',
    'G1.png',
    'G2.png',
    'G5.png',
    'G9.png',
    'G11.png',
    'G13.png',
    'G15.png',
    'exit.png',
    'binoculars.png',
    'door_breach_1.png',
    'door_breach_2.png',
    'earthquake.png',
    'flooding_1.png',
    'flooding.png', // known as flooding_2.png in other dictionaries
    'winter_storm.png',
    'fallen_tree.png',
    'water_2.png',
    'icon1.png',
    'icon6.png',
    'icon7.png',
    'icon8.png',
    'icon10.png',
    'icon11.png',
    'icon14.png',
    'LM1.png',
    'LM2.png',
    'LM3.png',
    'LM4.png',
    'LM6.png',
    'LM7.png',
    'LM11.png',
    'LM13.png',
    'LM20.png',
    'LM21.png',
    'LM70.png',
    'LM48.png',
    'LM23.png',
    'LM24.png',
    'LM26.png',
    'LM27.png',
    'LM29.png',
    'LM31.png',
    'LM32.png',
    'LM33.png',
    'LM34.png',
    'LM35.png',
    'LM36.png',
    'LM37.png',
    'LM38.png',
    'LM39.png',
    'LM45.png',
    'beer.png',
    'do_not_enter.png',
    'explosive_1.png',
    'explosive_2.png',
    'gas_green.png',
    'gas_red.png',
    'gas_white.png',
    'gas_yellow.png',
    'gen_0.png',
    'gen_1.png',
    'generator.png',
    'generator_2.png',
    'golf_cart.png',
    'green_checkbox.png',
    'ied_1.png',
    'ied_2.png',
    'misc_gis.png',
    'money.png',
    'no_comms.png',
    'no_go.png',
    'no_power.png',
    'no_water.png',
    'parking.png',
    'special_event.png',
    'special_event_2.png',
    'tower.png',
    'traffic_0.png',
    'traffic_1.png',
    'LM46.png',
    'LM50.png',
    'LM55.png',
    'LM51.png',
    'LM56.png',
    'LM59.png',
    'LM60.png',
    'LM61.png',
    'LM62.png',
    'LM47.png',
    'LM52.png',
    'LM57.png',
    'LM63.png',
    'LM64.png',
    'LM65.png',
    'LM66.png',
    'LM85.png',
    'emergency_siren_0.png',
    'emergency_siren_1.png',
    'windsock.png',
    'direction_north.png',
    'direction_northwest.png',
    'direction_west.png',
    'direction_southwest.png',
    'direction_south.png',
    'direction_southeast.png',
    'direction_east.png',
    'direction_northeast.png',
    'EOC.png',
    'cs_yellow.png',
    'cs_red.png',
    'drowning.png',
    'rising_waters.png',
    'sinking.png',
  ],
  incident_location: [
    'church.png',
    'mosque.png',
    'synagog.png',
    'damaged_structure.png',
    'doctors_office.png',
    'emergency_shelter.png',
    'hospital.png',
    'icon13.png',
    'LM44.png',
  ],
  law_enforcement: [
    'badge_blue.png',
    'badge_black.png',
    'badge_red.png',
    'L1.png',
    'L2.png',
    'L3.png',
    'G3.png',
    'G12.png',
    'LASD.png',
    'LASD_Assign1.png',
    'LASD_Assign2.png',
    'LASD_Assign3.png',
    'LASD_AssignA.png',
    'LASD_AssignB.png',
    'LASD_AssignC.png',
    'LASD_AssignD.png',
    'LM25.png',
    'LM71.png',
    'LM73.png',
    'LM74.png',
    'LM75.png',
    'LM76.png',
    'LM78.png',
    'LM79.png',
    'LM80.png',
    'LM58.png',
    'LM49.png',
    'LM53.png',
  ],
  fire_ems: [
    'F1.png',
    'F2.png',
    'F3.png',
    'F4.png',
    'F5.png',
    'F6.png',
    'G10.png',
    'G14.png',
    'hazmat.png',
    'er.png',
    'er_2.png',
    'LM8.png',
    'LM14.png',
    'icon12.png',
    'LM15.png',
    'LM16.png',
    'LM17.png',
    'LM18.png',
    'LM19.png',
    'LM9.png',
    'LM30.png',
    'LM83.png',
    'LM84.png',
    'LM67.png',
    'LM68.png',
    'LM69.png',
    'LM72.png',
    'LM82.png',
    'LM81.png',
    'icon5.png',
    'decon.png',
    'fire_attack_1.png',
    'fire_attack_2.png',
    'incident_command.png',
    'investigation.png',
    'primary_search.png',
    'rapid_intervention_crew.png',
    'road_block.png',
    'search_and_rescue_1.png',
    'search_and_rescue_2.png',
    'secondary_search.png',
    'ventilation_1.png',
    'ventilation_2.png',
    'PIV-mt.png',
    'SPIV-mt.png',
    'EOL-PIV-mt.png',
  ],
  utilities_oil: [
    'crewtruck.png',
    'servicetruck.png',
    'pressuretruck.png',
    'weldrig.png',
    'excavator.png',
    'vaporextractor.png',
    'directionaldrill.png',
    'supervisor.png',
    'manager.png',
    'operationschief.png',
    'fsrsingle.png',
    'fsrcrew.png',
    'mechanicsingle.png',
    'mechaniccrew.png',
    'leadersingle.png',
    'leadercrew.png',
    'weldersingle.png',
    'weldercrew.png',
    'constructionsingle.png',
    'constructioncrew.png',
    'surveycrewsingle.png',
    'surveycrewcrew.png',
    'testingcrewsingle.png',
    'testingcrewcrew.png',
    'publicinfo.png',
    'claimsregulatory.png',
    'leakcheck.png',
    'leakchecknotpresent.png',
    'leakcheckpresent.png',
    'evacuated.png',
    'venting.png',
    'odorcheck.png',
    'valveopen.png',
    'valveclosed.png',
    'controlpressure.png',
    'stopper.png',
    'squeezepoint.png',
    'bypass.png',
    'laneclosed.png',
    'roadclosed.png',
    'excavation.png',
  ],
  colors: [
    'color_1.png',
    'color_2.png',
    'color_3.png',
    'color_4.png',
    'color_5.png',
    'color_6.png',
    'color_7.png',
    'color_8.png',
    'color_9.png',
    'color_10.png',
    'color_11.png',
    'color_12.png',
    'color_13.png',
    'color_15.png',
    'color_16.png',
    'color_17.png',
    'color_18.png',
    'LM5.png',
    'LM10.png',
    'LM28.png',
  ],
  letters_numbers: [
    '01_light.png',
    '02_light.png',
    '03_light.png',
    '04_light.png',
    '05_light.png',
    '06_light.png',
    '07_light.png',
    '08_light.png',
    '09_light.png',
    '10_light.png',
    '01.png',
    '02.png',
    '03.png',
    '04.png',
    '05.png',
    '06.png',
    '07.png',
    '08.png',
    '09.png',
    '10.png',
    '11.png',
    '12.png',
    '13.png',
    '14.png',
    '15.png',
    '16.png',
    '17.png',
    '18.png',
    '19.png',
    '20.png',
    '21.png',
    '22.png',
    '23.png',
    '24.png',
    '25.png',
    '26.png',
    '27.png',
    '28.png',
    '29.png',
    '30.png',
    'a.png',
    'b.png',
    'c.png',
    'd.png',
    'e.png',
    'f.png',
    'g.png',
    'h.png',
    'i.png',
    'j.png',
    'k.png',
    'l.png',
    'm.png',
    'n.png',
    'o.png',
    'p.png',
    'q.png',
    'r.png',
    's.png',
    't.png',
    'u.png',
    'v.png',
    'w.png',
    'x.png',
    'y.png',
    'z.png',
    'za.png',
    'zb.png',
    'zc.png',
    'zd.png',
    'ze.png',
    'zf.png',
    'zg.png',
    'zh.png',
    'zi.png',
  ],
  rhodium: [
    'CommandScope.png',
    'Gate.png',
    'RhodiumPreplan.png',
    'Checkpoint.png',
  ],
};

export const tasksIcons = {
// Reference from Rhodium3 module/Application/view/partials/task_icons.phtml
  general: [
    'default.png',
    'icon3.png',
    'icon14.png',
    'G21.png',
    'G22.png',
    'F6.png',
    'G24.png',
    'G26.png',
    'G27.png',
    'G28.png',
    'exit.png',
    'binoculars.png',
    'door_breach_1.png',
    'door_breach_2.png',
    'earthquake.png',
    'flooding_1.png',
    'flooding_2.png',
    'winter_storm.png',
    'fallen_tree.png',
    'water_2.png',
    'icon1.png',
    'icon6.png',
    'icon7.png',
    'icon10.png',
    'icon11.png',
    'LM1.png',
    'LM2.png',
    'LM3.png',
    'LM4.png',
    'LM6.png',
    'LM7.png',
    'LM11.png',
    'LM12.png',
    'LM20.png',
    'helo_black.png',
    'helo_blue.png',
    'helo_red.png',
    'LM23.png',
    'LM24.png',
    'LM26.png',
    'LM27.png',
    'LM29.png',
    'LM31.png',
    'LM32.png',
    'LM33.png',
    'LM34.png',
    'LM35.png',
    'LM36.png',
    'LM37.png',
    'LM38.png',
    'LM39.png',
    'LM45.png',
    'beer.png',
    'do_not_enter.png',
    'explosive_1.png',
    'explosive_2.png',
    'gas_green.png',
    'gas_red.png',
    'gas_white.png',
    'gas_yellow.png',
    'gen_0.png',
    'gen_1.png',
    'generator.png',
    'generator_2.png',
    'golf_cart.png',
    'green_checkbox.png',
    'ied_1.png',
    'ied_2.png',
    'misc_gis.png',
    'money.png',
    'no_comms.png',
    'no_go.png',
    'no_power.png',
    'no_water.png',
    'parking.png',
    'special_event.png',
    'special_event_2.png',
    'tower.png',
    'traffic_0.png',
    'traffic_1.png',
    'C2.png',
    'C3.png',
    'G6.png',
    'G8.png',
    'G9.png',
    'G10.png',
    'G11.png',
    'G12.png',
    'G13.png',
    'G14.png',
    'G15.png',
    'G16.png',
    'G17.png',
    'G18.png',
    'G19.png',
    'G20.png',
    'G7.png',
    'emergency_siren_0.png',
    'emergency_siren_1.png',
    'windsock.png',
    'direction_north.png',
    'direction_northwest.png',
    'direction_west.png',
    'direction_southwest.png',
    'direction_south.png',
    'direction_southeast.png',
    'direction_east.png',
    'direction_northeast.png',
    'EOC.png',
    'cs_yellow.png',
    'cs_red.png',
    'drowning.png',
    'rising_waters.png',
    'sinking.png',
  ],
  incident_location: [
    'church.png',
    'mosque.png',
    'synagog.png',
    'damaged_structure.png',
    'doctors_office.png',
    'emergency_shelter.png',
    'hospital.png',
    'C1.png',
    'icon13.png',
  ],
  law_enforcement: [
    'badge_blue.png',
    'badge_black.png',
    'badge_red.png',
    'P4.png',
    'P7.png',
    'L3.png',
    'G23.png',
    'F7.png',
    'LASD.png',
    'LASD_Assign1.png',
    'LASD_Assign2.png',
    'LASD_Assign3.png',
    'LASD_AssignA.png',
    'LASD_AssignB.png',
    'LASD_AssignC.png',
    'LASD_AssignD.png',
    'LM25.png',
    'P1.png',
    'P2.png',
    'P3.png',
    'P5.png',
    'P6.png',
    'P8.png',
    'P9.png',
    'P10.png',
    'P13.png',
    'P11.png',
    'P12.png',
  ],
  fire_ems: [
    'F10.png',
    'F11.png',
    'F12.png',
    'F13.png',
    'F14.png',
    'F15.png',
    'G25.png',
    'M2.png',
    'hazmat.png',
    'er.png',
    'er_2.png',
    'LM8.png',
    'LM14.png',
    'icon12.png',
    'LM15.png',
    'LM16.png',
    'LM17.png',
    'LM18.png',
    'LM19.png',
    'LM9.png',
    'LM30.png',
    'M1.png',
    'M3.png',
    'F1.png',
    'F2.png',
    'F3.png',
    'F4.png',
    'F5.png',
    'F8.png',
    'F9.png',
    'decon.png',
    'fire_attack_1.png',
    'fire_attack_2.png',
    'incident_command.png',
    'investigation.png',
    'primary_search.png',
    'rapid_intervention_crew.png',
    'road_block.png',
    'search_and_rescue_1.png',
    'search_and_rescue_2.png',
    'secondary_search.png',
    'ventilation_1.png',
    'ventilation_2.png',
    'PIV-tsk.png',
    'SPIV-tsk.png',
    'EOL-PIV-tsk.png',
  ],
  utilities_oil: [
    'crewtruck.png',
    'servicetruck.png',
    'pressuretruck.png',
    'weldrig.png',
    'excavator.png',
    'vaporextractor.png',
    'directionaldrill.png',
    'supervisor.png',
    'manager.png',
    'operationschief.png',
    'fsrsingle.png',
    'fsrcrew.png',
    'mechanicsingle.png',
    'mechaniccrew.png',
    'leadersingle.png',
    'leadercrew.png',
    'weldersingle.png',
    'weldercrew.png',
    'constructionsingle.png',
    'constructioncrew.png',
    'surveycrewsingle.png',
    'surveycrewcrew.png',
    'testingcrewsingle.png',
    'testingcrewcrew.png',
    'publicinfo.png',
    'claimsregulatory.png',
    'leakcheck.png',
    'leakchecknotpresent.png',
    'leakcheckpresent.png',
    'evacuated.png',
    'venting.png',
    'odorcheck.png',
    'valveopen.png',
    'valveclosed.png',
    'controlpressure.png',
    'stopper.png',
    'squeezepoint.png',
    'bypass.png',
    'laneclosed.png',
    'roadclosed.png',
    'excavation.png',
  ],
  colors: [
    'color_1.png',
    'color_2.png',
    'color_3.png',
    'color_4.png',
    'color_5.png',
    'color_6.png',
    'color_7.png',
    'color_8.png',
    'color_9.png',
    'color_10.png',
    'color_11.png',
    'color_12.png',
    'color_13.png',
    'color_15.png',
    'color_16.png',
    'color_17.png',
  ],
  letters_numbers: [
    '01.png',
    '02.png',
    '03.png',
    '04.png',
    '05.png',
    '06.png',
    '07.png',
    '08.png',
    '09.png',
    '10.png',
    '01_dark.png',
    '02_dark.png',
    '03_dark.png',
    '04_dark.png',
    '05_dark.png',
    '06_dark.png',
    '07_dark.png',
    '08_dark.png',
    '09_dark.png',
    '10_dark.png',
    '11.png',
    '12.png',
    '13.png',
    '14.png',
    '15.png',
    '16.png',
    '17.png',
    '18.png',
    '19.png',
    '20.png',
    '21.png',
    '22.png',
    '23.png',
    '24.png',
    '25.png',
    '26.png',
    '27.png',
    '28.png',
    '29.png',
    '30.png',
    'a.png',
    'b.png',
    'c.png',
    'd.png',
    'e.png',
    'f.png',
    'g.png',
    'h.png',
    'i.png',
    'j.png',
    'k.png',
    'l.png',
    'm.png',
    'n.png',
    'o.png',
    'p.png',
    'q.png',
    'r.png',
    's.png',
    't.png',
    'u.png',
    'v.png',
    'w.png',
    'x.png',
    'y.png',
    'z.png',
    'za.png',
    'zb.png',
    'zc.png',
    'zd.png',
    'ze.png',
    'zf.png',
    'zg.png',
    'zh.png',
    'zi.png',
  ],
  rhodium: [
    'CommandScope.png',
    'Gate.png',
    'RhodiumPreplan.png',
    'Checkpoint.png',
  ],
};
